/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import './simpleCustomSelect.css';
import { Divider } from '@material-ui/core';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, setOpen) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert('You clicked outside of me!');
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */

const SimpleCustomSelect = ({
  error,
  type,
  name,
  value,
  placeholder,
  handleField,
  setFieldValue,
  options,
}) => {
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);
  useOutsideAlerter(wrapperRef, setOpen);
  const toggle = () => {
    setOpen((prev) => !prev);
  };
  const handleChange = (v) => {
    setOpen(false);
    console.log('v', v);
    handleField(v);
    setFieldValue(name, v);
  };
  console.log('value', value);

  return (
    <>
      <div
        ref={wrapperRef}
        className="countryDropdown"
        style={error ? { border: '1px solid red' } : null}
      >
        <div className="countryDropdown-selected-area" onClick={() => toggle()}>
          <span>{value ? value : placeholder}</span>
          {open ? (
            <i class="fas fa-caret-up"></i>
          ) : (
            <i class="fas fa-caret-down"></i>
          )}
        </div>
        <ul
          style={{ display: open ? 'block' : 'none' }}
          className="countries-list"
        >
          {options.map((option, i) => (
            <>
              <li key={i} onClick={() => handleChange(option)}>
                <span>{option}</span>
              </li>
              <Divider />
            </>
          ))}
        </ul>
      </div>
      <p style={error ? null : { display: 'none' }} className="birthDateErr">
        {error}
      </p>
    </>
  );
};

export default SimpleCustomSelect;

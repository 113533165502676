import React, { useState } from 'react';
import coins from '../../../../assets/images/walp1.jpg';
import rightarrow from '../../../../assets/images/rightarrow.svg';
import './homeBanner.css';
import { Dialog } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogActions } from '@mui/material';
import { Button, Box, Chip, Divider } from '@mui/material';
import imageUrl from '../../../../assets/images/banner.jpg';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const HomeBanner = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openLink = (link) => {
    window.open(link, '_blank', 'noreferrer')
  };

  return (
    <div className="homeBanner">
      <div className="left-text">
        <p className="homeBanner-text-h">Invierte con nosotros</p>
        <p className="homeBanner-text">
          Seguridad y confianza en todas tus transacciones
        </p>
        <button className="comprar-btn" onClick={handleOpen}>
          Comprar <img src={rightarrow} alt="rightarrow" />
        </button>
      </div>
      <img className="rightimg" src={coins} alt="coins" />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            color: '#051840',
            borderRadius: 10,
            backgroundColor: 'rgba(242, 242, 242, 0.90)',
            boxShadow: '0 0 30px rgba(0, 0, 0, 0.5)',
            overflow: 'hidden',
          },
        }}
      >
        <DialogTitle
          sx={{
            // backgroundColor: '#f2f2f2',
            fontWeight: 'bold',
            padding: '16px 24px',
            borderBottom: '1px solid #333',
            border: 'none'
          }}
        >
          Para comprar debes de seguir estos pasos:
        </DialogTitle>
        <DialogContent
          sx={{
            // backgroundColor: '#f2f2f2',
            padding: '24px',
          }}
        >
          <Box
             sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >             
            {/*  */}
            <ul
              style={{
                listStyleType: "disc",
                textAlign: "justify",
                fontSize: "18px",
                marginTop: "15px",
                marginBottom: "20px",
                paddingLeft: "20px",
              }}
            >
              <li className="popupLi">Primero debes indicarnos la criptomoneda que deseas comprar. Por ejemplo: USDT, Bitcoin, Ethereum, etc.</li>
              <li className="popupLi">Luego deberás indicarnos donde te gustaría recibir tus criptomonedas. Por ejemplo: Binance, Coinbase, Metamask, TrustWallet, etc.</li>
              <li className="popupLi">Después te indicaremos el cambio actual en quetzales de la criptomoneda que deseas comprar.</li>
              <li className="popupLi">A continuación, debes realizar tu proceso de identificación KYC en nuestra página ortech.com.gt, este proceso se realiza solo la primera vez que comercias con nosotros, luego ya podremos identificarte.</li>
              <li className="popupLi">Luego de haber realizado tu identificación KYC, Te proporcionaremos nuestra cuenta bancaria y nos podrás realizar la transferencia. NOTA: Únicamente trabajamos por transferencia bancaria, depósitos en efectivo NO son aceptados. Tampoco puedes transferir de una cuenta que no esté identificada con el nombre con el cual te identificaste en el KYC.</li>
              <li className="popupLi">Una vez que hayamos recibido los fondos y tu comprobante de pago, procederemos a enviarte tus criptomonedas a donde tu nos hayas indicado.</li>
            </ul>
            {/* Welcome message */}
            <p style={{ fontSize: "20px", marginBottom: "15px" }}>
                  Listo, esto finaliza el proceso y te damos la bienvenida al mundo de las criptomonedas.
            </p>  
            <p style={{ fontSize: "20px", marginBottom: "15px" }}>¡Feliz Viaje!</p>
            <Chip
               style={{
                backgroundColor: '#122B6C',
                marginBottom: '15px',
                marginTop: '15px',
              }}
             label="Nota: Puedes comprar desde 50 quetzales" color="primary" />
            {/* List of potential uses */}
            <p className="popupP" style={{ fontSize: "18px", marginTop: "20px", marginBottom: "5px" }}>
              Ahora bien, aquí hay una serie de usos que les puedes dar:
            </p>
            <ul
               style={{
                marginTop: "10px",
                listStyleType: "none",
                textAlign: "justify",
                fontSize: "18px",
                paddingLeft: "20px",
              }}
            >
              <li className='popupLi'>
                Recibir pagos
              </li>
              <li className='popupLi'>
                Invertir
              </li>
              <li className='popupLi'>
                Diversificar portafolio
              </li>
              <li className='popupLi'>
                Realizar trading
              </li>
              <li className='popupLi'>
                Enviar criptomonedas a cualquier persona en el mundo
              </li>
            </ul>
            {/*  */}
            <Button
              color="primary"
              aria-label="Comprar Ahora"
              component="span"
              variant="contained"
              sx={{ 
                marginTop: '10px',
                backgroundColor: '#25D366',
                '&:hover': {
                  backgroundColor: '#075E54',
                },
              }}
              endIcon={<WhatsAppIcon />}
              onClick={()=>openLink("https://api.whatsapp.com/send?phone=50230994927&text=Hola,%20un%20gusto%20saludarte%20por%20este%20medio.%20¿Podrías%20ayudarme%20con%20unas%20consultas?")}
            >
              Comprar Ahora
            </Button>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            // backgroundColor: '#f2f2f2',
            padding: '16px 24px',
            borderTop: '1px solid #333',
            border: 'none'
          }}
        >
          <Button onClick={handleClose} 
           sx={{
            backgroundColor: '#102C6E',
            '&:hover': {
              backgroundColor: '#314570',
            },
          }}
            color="primary" variant="contained">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HomeBanner;

/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@mui/material';
import KycInput from '../kycInput';
import UploadImage from '../uploadFile';
import SimpleCustomSelect from '../../../../../../components/simpleCustomSelect';

const CompanyGeneralInformation = ({
  kybData,
  setKybData,
  handleUploadClick,
  activeStep,
  handleNext,
  handlePrevious,
  extraDocProgress,
  businessLicenseProgress,
  societyPatentProgress,
  rTUProgress,
}) => {
  const checkoutSchema = yup.object().shape({
    businessActivity: yup.string().required('Requerido'),
    businessFundsSource: yup.string().required('Requerido'),
    businessEstimatedRange: yup.string().required('Requerido'),
    // extraDoc: yup.string().required('Requerido'),
  });
  const handleEstimatedRange = (value) => {
    setKybData({ ...kybData, businessEstimatedRange: value });
  };
  const handleFormSubmit = async (values) => {
    handleNext(values);
  };
  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={kybData}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <>
            <KycInput
              error={touched.businessActivity && errors.businessActivity}
              type="text"
              name="businessActivity"
              value={values.businessActivity}
              handleField={handleChange}
              placeholder="Actividad económica*"
            />
            <KycInput
              error={touched.businessFundsSource && errors.businessFundsSource}
              type="text"
              name="businessFundsSource"
              value={values.businessFundsSource}
              handleField={handleChange}
              placeholder="Procedencia de los fondos*"
            />
            <SimpleCustomSelect
              error={touched.businessEstimatedRange && errors.businessEstimatedRange}
              type="text"
              name="businessEstimatedRange"
              value={values.businessEstimatedRange}
              handleField={handleEstimatedRange}
              setFieldValue={setFieldValue}
              placeholder="Rango de fondos a manejar*"
              options={['$500-$1,000', '$1,000-$3,000', '$3,000-$10,000','$10,000-$20,000', '$20,000-$50,000', '$50,000-$200,000', '$200,000+']}
            />
            {/* extra document */}
            <p className="kycForm-txt2">Espacio para subir documentos adicionales (Opcional)</p>
            <UploadImage
              name="extraDoc"
              handleUploadClick={handleUploadClick}
              text="Cargar Foto"
              setFieldValue={setFieldValue}
            />
            &nbsp; &nbsp; &nbsp;
            {extraDocProgress > 0 ? (
              <span>
                Cargado &nbsp;
                <span style={{ color: 'green' }}>
                  {extraDocProgress} %
                </span>
              </span>
            ) : null}
            {errors.extraDoc && (
              <span style={{ color: 'red' }}>
                {errors.extraDoc}
              </span>
            )}
            &nbsp; &nbsp; &nbsp;
            {kybData.extraDoc && (
              <a href={kybData.extraDoc} target="_blank">
                Visualizar
              </a>
            )}
          </>
          <div className="stepperkycForm-enter-btn">
            <Button
              variant="contained"
              onClick={() => handlePrevious(values)}
              disabled={activeStep === 0}
            >
              Anterior
            </Button>
            <Button type="submit" variant="contained">
              Siguiente
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CompanyGeneralInformation;

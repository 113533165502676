import React from 'react';
import aboutusImg from '../../../../assets/images/aboutusImg.png';
import aboutUsImgpc from '../../../../assets/images/aboutUsImgpc.png';
import './aboutUs.css';

const AboutUs = () => {
  return (
    <div className="aboutUs">
      <div className="aboutUs-pc">
        <img className="aboutusImgPc" src={aboutUsImgpc} alt="aboutusImg" />
        <div>
          <p className="aboutUs-text1">Sobre nosotros</p>
          <p className="aboutUs-text2">
            Somos Proveedores de Servicios de Activos Virtuales que buscamos
            proveer y brindar un servicio fácil seguro y confiable en donde
            todas las personas tanto dentro o fuera de Guatemala tengan una
            conexión física-virtual con sus finanzas siendo ellos los dueños
            directos de sus criptomonedas (activos digitales)
          </p>
        </div>
      </div>
      <div className="aboutUs-mobile">
        <p className="aboutUs-text1">Sobre nosotros</p>
        <p className="aboutUs-text2">
          Somos Proveedores de Servicios de Activos Virtuales que buscamos
          proveer y brindar un servicio fácil seguro y confiable en donde todas
          las personas tanto dentro o fuera de Guatemala tengan una conexión
          física-virtual con sus finanzas siendo ellos los dueños directos de
          sus criptomonedas (activos digitales)
        </p>
        <br />
        <div className="aboutusImgMobile-w">
          <img className="aboutusImgMobile" src={aboutusImg} alt="aboutusImg" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import FormStepper from '../../components/kycFormStepper';
import KycForm from './components/kycForm';
import KycText from './components/kycText';
import './kycStepsForm.css';

const KycStepsForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    'Datos Generales',
    'Datos Personales',
    'Datos Profesionales',
    'Datos Económicos',
    'Carga de Documentos',
  ];
  return (
    <div className="kyc">
      <KycText />
      <FormStepper activeStep={activeStep} steps={steps} />
      <KycForm activeStep={activeStep} setActiveStep={setActiveStep} />
      <ToastContainer />
    </div>
  );
};

export default KycStepsForm;

import React from 'react';
import './kycText.css';

const KycText = () => {
  return (
    <div className="kycText">
      <div className="inner-w">
        <h1 className="kycText1">Identificación KYC</h1>
        <p className="kycText2">
          El proceso de KYC (Know Your Customer o Conoce a tu cliente por sus siglas en ingles) nos permite conocerte mejor y así brindarte un mejor servicio, mas seguro y confiable al momento de realizar cualquier transacción. Este proceso debe realizarse siempre al hacer tu primer comercio y con tu número de teléfono te identificaremos la próxima vez para hacer tu experiencia mucho mas rápida y ágil. Este proceso debe de ser realizado por todos los clientes sin excepción
        </p>
      </div>
    </div>
  );
};

export default KycText;

/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@mui/material';
import UploadImage from '../uploadFile';
import KycInput from '../kycInput';
import selfieDPI from '../../../../../../assets/images/selfie.jpg';

const LegalRepresentationInformation = ({
  kybData,
  handleUploadClick,
  legalRepresentativesIDCardFrontProgress,
  legalRepresentativesIDCardHoldProgress,
  legalRepresentativesIDCardBackProgress,
  appointmentOfLegalRepresentativeProgress,
  activeStep,
  setActiveStep,
  handleNext,
  handlePrevious,
}) => {
  const checkoutSchema = yup.object().shape({
    legalRepresentativesIDCardFront: yup.string().required('Requerido'),
    legalRepresentativesIDCardBack: yup.string().required('Requerido'),
    legalRepresentativesIDCardHold: yup.string().required('Requerido'),
    appointmentOfLegalRepresentative: yup.string().required('Requerido'),
    companyNit: yup.string().required('Requerido'),
  });
  const handleFormSubmit = async (values) => {
    handleNext(values, true);
  };
  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={kybData}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <>
            <KycInput
              error={touched.companyNit && errors.companyNit}
              type="text"
              name="companyNit"
              value={values.companyNit}
              handleField={handleChange}
              placeholder="Nit o DPI del representante legal*"
            />
            {/*  */}
            <p className="kycForm-txt2">Nombramiento del representante legal</p>
            <UploadImage
              name="appointmentOfLegalRepresentative"
              handleUploadClick={handleUploadClick}
              text="Cargar Foto"
              setFieldValue={setFieldValue}
            />
            &nbsp; &nbsp; &nbsp;
            {appointmentOfLegalRepresentativeProgress > 0 ? (
              <span>
                Cargado &nbsp;
                <span style={{ color: 'green' }}>
                  {appointmentOfLegalRepresentativeProgress} %
                </span>
              </span>
            ) : null}
            {errors.appointmentOfLegalRepresentative && (
              <span style={{ color: 'red' }}>
                {errors.appointmentOfLegalRepresentative}
              </span>
            )}
            &nbsp; &nbsp; &nbsp;
            {kybData.appointmentOfLegalRepresentative && (
              <a
                href={kybData.appointmentOfLegalRepresentative}
                target="_blank"
              >
                Visualizar
              </a>
            )}
            {/*  */}
            <p className="kycForm-txt2">
              DPI del representante legal (frontal)
            </p>
            <UploadImage
              name="legalRepresentativesIDCardFront"
              handleUploadClick={handleUploadClick}
              text="Cargar Foto"
              setFieldValue={setFieldValue}
            />
            &nbsp; &nbsp; &nbsp;
            {legalRepresentativesIDCardFrontProgress > 0 ? (
              <span>
                Cargado &nbsp;
                <span style={{ color: 'green' }}>
                  {legalRepresentativesIDCardFrontProgress} %
                </span>
              </span>
            ) : null}
            {errors.legalRepresentativesIDCardFront && (
              <span style={{ color: 'red' }}>
                {errors.legalRepresentativesIDCardFront}
              </span>
            )}
            &nbsp; &nbsp; &nbsp;
            {kybData.legalRepresentativesIDCardFront && (
              <a href={kybData.legalRepresentativesIDCardFront} target="_blank">
                Visualizar
              </a>
            )}
            {/*  */}
            <p className="kycForm-txt2">
              DPI del representante legal (trasero)
            </p>
            <UploadImage
              name="legalRepresentativesIDCardBack"
              handleUploadClick={handleUploadClick}
              text="Cargar Foto"
              setFieldValue={setFieldValue}
            />
            &nbsp; &nbsp; &nbsp;
            {legalRepresentativesIDCardBackProgress > 0 ? (
              <span>
                Cargado &nbsp;
                <span style={{ color: 'green' }}>
                  {legalRepresentativesIDCardBackProgress} %
                </span>
              </span>
            ) : null}
            {errors.legalRepresentativesIDCardBack && (
              <span style={{ color: 'red' }}>
                {errors.legalRepresentativesIDCardBack}
              </span>
            )}
            &nbsp; &nbsp; &nbsp;
            {kybData.legalRepresentativesIDCardBack && (
              <a href={kybData.legalRepresentativesIDCardBack} target="_blank">
                Visualizar
              </a>
            )}
            {/*  */}
            <p className="kycForm-txt2">
              Foto del DPI del representante legal Sostenido
            </p>
            <UploadImage
              name="legalRepresentativesIDCardHold"
              handleUploadClick={handleUploadClick}
              text="Cargar Foto"
              setFieldValue={setFieldValue}
            />
            &nbsp; &nbsp; &nbsp;
            {legalRepresentativesIDCardHoldProgress > 0 ? (
              <span>
                Cargado &nbsp;
                <span style={{ color: 'green' }}>
                  {legalRepresentativesIDCardHoldProgress} %
                </span>
              </span>
            ) : null}
            {errors.legalRepresentativesIDCardHold && (
              <span style={{ color: 'red' }}>
                {errors.legalRepresentativesIDCardHold}
              </span>
            )}
            &nbsp; &nbsp; &nbsp;
            {kybData.legalRepresentativesIDCardHold && (
              <a href={kybData.legalRepresentativesIDCardHold} target="_blank">
                Visualizar
              </a>
            )}
            <p className="kycForm-txt2">Ejemplo:</p>
            <img
              className="selfieDPI"
              src={selfieDPI}
              alt="Ejemplo Selfie DPI"
            />
            <br />
          </>
          <div className="stepperkycForm-enter-btn">
            <Button
              variant="contained"
              onClick={() => handlePrevious(values)}
              disabled={activeStep === 0}
            >
              Anterior
            </Button>
            <Button type="submit" variant="contained">
              Enviar
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default LegalRepresentationInformation;

import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import FormStepper from '../../components/kybFormStepper';
import KybForm from './components/kybForm';
import KybText from './components/kybForm/components/kybText';

import './knowYourBusiness.css';

const KnowYourBusiness = () => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    'Datos Generales',
    'Datos de la Compañia',
    'Información',
    'Datos del representante legal'
  ];
  return (
    <div className="know-your-business">
      <KybText />
      <FormStepper activeStep={activeStep} steps={steps} />
      <KybForm activeStep={activeStep} setActiveStep={setActiveStep} />
      <ToastContainer />
    </div>
  );
};

export default KnowYourBusiness;

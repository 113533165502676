import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@mui/material';
import KycInput from '../kycInput';

const GeneralInformation = ({
  kybData,
  setKybData,
  activeStep,
  handleNext,
}) => {
  const checkoutSchema = yup.object().shape({
    companyPhoneNumber: yup.string().required('Requerido'),
    companyName: yup.string().required('Requerido'),
    email: yup.string().email('invalid email').required('Requerido'),
  });
  const handleFormSubmit = async (values) => {
    console.log('values', values);
    handleNext(values);
  };
  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={kybData}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <>
            <KycInput
              error={touched.companyName && errors.companyName}
              type="text"
              name="companyName"
              value={values.companyName}
              handleField={handleChange}
              placeholder="Nombre de la empresa*"
            />
            <KycInput
              error={touched.companyPhoneNumber && errors.companyPhoneNumber}
              type="text"
              name="companyPhoneNumber"
              value={values.companyPhoneNumber}
              handleField={handleChange}
              placeholder="Número de teléfono de la empresa*"
            />
            <KycInput
              error={touched.email && errors.email}
              type="email"
              name="email"
              value={values.email}
              handleField={handleChange}
              placeholder="Correo electrónico*"
            />
          </>

          <div className="stepperkycForm-enter-btn">
            <Button variant="contained" disabled={activeStep === 0}>
              Anterior
            </Button>
            <Button type="submit" variant="contained">
              Siguiente
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default GeneralInformation;

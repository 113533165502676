import React from 'react';
import './kybText.css';

const KybText = () => {
  return (
    <div className="kycText">
      <div className="inner-w">
        <h1 className="kycText1">Identificación KYB</h1>
        <p className="kycText2">
          El proceso de KYB (Know Your Business o Conoce Tu Negocio por sus siglas en
          inglés) nos permite identificar y verificar los negocios y así brindarte un mejor
          servicio, más seguro y confiable al momento de realizar cualquier transacción.
          Evitando establecer vínculos con empresas ficticias o sociedades ilícitas. Este
          proceso debe realizarse siempre al hacer tu primer comercio y debe de ser
          realizado por todos los clientes que deseen trabajar con empresas sin excepción.
        </p>
      </div>
    </div>
  );
};

export default KybText;

import React from 'react';
import './services.css';

const Services = () => {
  return (
    <div className="services-wrapper">
      <h1 className="services-h1">Servicios</h1>
      <p className="services-text">
      Comprometidos a entregar la mejor atención en cualquier momento del dia, ponemos a tu disposición nuestros servicios, los cuales encontraras muy faciles y seguros de usar siempre con el mejor respaldo.
      </p>
    </div>
  );
};

export default Services;

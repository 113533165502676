import React from 'react';
import './whiteCard.css';

const WhiteCard = ({ title, image, text }) => {
  return (
    <div className="whiteCard-wrapper">
      <img className="whiteCard-image" src={image} alt="tokenswap" />
      <h3 className="whiteCard-heading">{title}</h3>
      <p className="whiteCard-text">
        {text}
      </p>
    </div>
  );
};

export default WhiteCard;

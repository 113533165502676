import React from 'react';
import './aboutUs.css';

const aboutUs = () => {
  return (
    <div className="aboutUs">
      <h1 className="aboutUs-heading">Acerca De</h1>
      <p className="aboutUs-brief-txt">
        ORTECH Oraculum Technologies Sociedad Anónima
      </p>
      <div className="aboutUs-inner">
        <p>
          <strong>Acerca de (Descripción del Proyecto)</strong>
          <br />
          <br />
          ORTECH Oraculum Technologies Sociedad Anónima fue fundada en el año
          2022 por el Ingeniero Civil Angel Alfonso Gómez Cheng, con capital
          Guatemalteco que nace como parte de una solución para que cualquier
          persona dentro de Guatemala pueda adquirir, liquidar invertir sus
          criptomonedas (activos digitales). Permitiendo de este modo que
          cualquier empresa o persona individual independiente del país,
          cultura, raza o idioma pueda intercambiar sus activos digitales en una
          manera rápida, segura, simple y confiable.
          <br />
          <br />
          <br />
          <strong>Misión</strong>
          <br />
          <br />
          Proveer y brindar un servicio fácil seguro y confiable en donde todas
          las personas tanto dentro o fuera de Guatemala tengan una conexión
          física-virtual con sus finanzas siendo ellos los dueños directos de
          sus criptomonedas (activos digitales)
          <br />
          <br />
          <br />
          <strong>Visión</strong>
          <br />
          <br />
          Convertirse a nivel mundial en un proveedor fundamental de activos
          digitales, criptomonedas para expandir las formas de pago, negocio e
          inversión en Guatemala y en toda América Latina. Brindando
          oportunidades de superación a los usuarios incrementando el uso de
          activos digitales de la mano del desarrollo que dichos activos
          presentan actualmente. <br />
          <br />
          <br />
          <br />
          <strong>Valores</strong>
          <br />
          <br />
          Brindar la mejor orientación a los clientes en cuanto al modo de
          invertir, comprar o almacenar sus activos digitales dándoles la
          información necesaria para la adecuada toma de decisiones esmerándonos
          en cada clientes y aportándoles las mejores opciones de compra, venta
          e inversión al ser asesorados por especialistas en este tipo de
          negocio.
          <br />
          <br />
          <br />
          <strong>Objetivo General</strong>
          <br />
          <br />
          Implementar un sistema de compra y venta de criptomonedas (activos
          digitales) accesible para cualquier persona y que permita crear
          comercio y facilidades de pago que puedan expandir los mercados de
          Guatemala. <br />
          <br />
          <br />
          <a href="/términos-y-políticas" target='_blank'>
            <h3 className="plinkText">Términos y Condiciones</h3>
          </a>
          <br />
        </p>
      </div>
    </div>
  );
};

export default aboutUs;

import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@mui/material';
import KycInput from '../kycInput';
import DatePicker from 'react-date-picker';
import CountryDropdown from '../countryDropdown';
import SimpleCustomSelect from '../../../../../../components/simpleCustomSelect';

const generalDetails = ({ kycData, setKycData, activeStep, handleNext }) => {
  const checkoutSchema = yup.object().shape({
    email: yup.string().email('invalid email').required('Requerido'),
    phone: yup.string().required('Requerido'),
    country: yup.object().shape({
      name: yup.string().required('Requerido'),
    }),
  });
  const handleGender = (value) => {
    setKycData({ ...kycData, gender: value });
  };
  const handleBirthDate = (value) => {
    setKycData({ ...kycData, birthDate: value });
  };
  const handleCountry = (value) => {
    setKycData({ ...kycData, country: value });
  };
  const handleFormSubmit = async (values) => {
    console.log('values', values);
    handleNext(values);
  };
  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={kycData}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <>
            <KycInput
              error={touched.phone && errors.phone}
              type="text"
              name="phone"
              value={values.phone}
              handleField={handleChange}
              placeholder="Número de teléfono*"
            />
            <KycInput
              error={touched.email && errors.email}
              type="email"
              name="email"
              value={values.email}
              handleField={handleChange}
              placeholder="Email*"
            />
            <CountryDropdown
              error={touched.country && errors.country}
              type="text"
              name="country"
              value={values.country}
              setFieldValue={setFieldValue}
              handleField={handleCountry}
              placeholder="País de origen*"
            />
          </>
          <div className="stepperkycForm-enter-btn">
            <Button variant="contained" disabled={activeStep === 0}>
              Anterior
            </Button>
            <Button type="submit" variant="contained">
              Siguiente
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default generalDetails;

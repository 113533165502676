import React from 'react';
import empizaimg1 from '../../../../assets/images/empizaimg1.png';
import empizaimg2 from '../../../../assets/images/empizaimg2.png';
import rightarrow from '../../../../assets/images/rightarrow.svg';
import './empizaComprar.css';

const EmpizaComprar = () => {
  const openLink = (link) => {
    window.open(link, '_blank', 'noreferrer')
  };
  return (
    <div className="empizaComprar-wrapper">
      <div className="empieza-txt-box">
        <div>
          <p className="empizaComprar-txt1">Empieza tu viaje Crypto</p>
          <p className="empizaComprar-txt2">
            ¿Quieres invertir en criptomonedas pero no sabes por dónde empezar?
            ¡Tenemos la solución perfecta para ti!
          </p>
          <button className="empizaComprar-btn"  onClick={()=>openLink("https://api.whatsapp.com/send?phone=50230994927&text=Hola,%20un%20gusto%20saludarte%20por%20este%20medio.%20¿Podrías%20ayudarme%20con%20unas%20consultas?")}>
            Empieza a comprar y vender hoy
            <img src={rightarrow} alt="rightarrow" />
          </button>
        </div>
      </div>
      <img className="empizaimg1img" src={empizaimg1} alt="empizaimg1" />
      <img className="empizaimg2img" src={empizaimg2} alt="empizaimg2" />
    </div>
  );
};

export default EmpizaComprar;

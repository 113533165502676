import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@mui/material';
import KycInput from '../kycInput';

const ProfesionalDetails = ({
  kycData,
  setKycData,
  activeStep,
  handleNext,
  handlePrevious,
}) => {
  const checkoutSchema = yup.object().shape({
    dpi: yup.string().required('Requerido'),
    tin: yup.string().required('Requerido'),
    address: yup.string().required('Requerido'),
  });
  const handleFormSubmit = async (values) => {
    handleNext(values);
  };
  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={kycData}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          {console.log('errors', errors)}
          {console.log('touched', touched)}
          <>
            <KycInput
              error={errors.dpi}
              type="text"
              name="dpi"
              value={values.dpi}
              handleField={handleChange}
              placeholder="Número de DPI*"
            />
            <KycInput
              error={touched.tin && errors.tin}
              type="text"
              name="tin"
              value={values.tin}
              handleField={handleChange}
              placeholder="NIT o CF*"
            />
            <KycInput
              error={touched.address && errors.address}
              type="text"
              name="address"
              value={values.address}
              handleField={handleChange}
              placeholder="Dirección*"
            />
          </>
          <div className="stepperkycForm-enter-btn">
            <Button
              variant="contained"
              onClick={() => handlePrevious(values)}
              disabled={activeStep === 0}
            >
              Anterior
            </Button>
            <Button type="submit" variant="contained">
              Siguiente
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ProfesionalDetails;

import React from 'react';
import WhiteCard from '../whiteCard';
import tokenswap from '../../../../assets/images/tokenswap.svg';
import peerToPeer from '../../../../assets/images/peerToPeer.svg';
import transactionFee from '../../../../assets/images/transactionFee.svg';
import './cards.css';
// import BlueCard from '../blueCard';

const Cards = () => {
  return (
    <div className="cards-w">
      <WhiteCard
        title="Negociación de Activos Digitales Criptomonedas"
        image={tokenswap}
        text="Compra y vende criptomonedas de una manera, sencilla, fácil, segura y recibiéndolas en tu billetera personal."
      />
      <div className="pc-spacer40" />
      <WhiteCard
        title="Servicio de Backtesting (próximamente)"
        image={peerToPeer}
        text="Obtén un análisis del rendimiento potencial de tu estrategia en datos históricos a partir de tus parámetros, para que puedas implementarla en tu día a día."
      />
      <div className="pc-spacer40" />
      <WhiteCard
        title="Consultas"
        image={transactionFee}
        text="Recibe ayuda personalizada sobre diversas gestiones que desees realizar de la manera más segura."
      />
    </div>
  );
};

export default Cards;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import * as yup from 'yup';
import { Formik } from 'formik';
import './userInfoForm.css';

const UserInfoForm = () => {
  const [initialState, setInitialState] = useState({
    name: '',
    email: '',
    message: '',
  });
  const checkoutSchema = yup.object().shape({
    name: yup.string().required('Requerido'),
    email: yup.string().email('invalid email').required('Requerido'),
    message: yup.string().required('Requerido'),
  });
  const sendEmail = (data) => {
    const emailContent = {
      user_name: data.name,
      user_email: data.email,
      subject: 'Thank you for your response',
      message: data.message,
    };
    emailjs
      .send(
        'service_wco4flm',
        'template_eikedqy',
        emailContent,
        'Gw3IGZc93ZZdsq06Z'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const handleFormSubmit = async (values, { resetForm }) => {
    console.log('values', values);
    sendEmail(values);
    setInitialState({
      name: '',
      email: '',
      message: '',
    });
    resetForm({ values: '' });
    toast('✅ Form Submitted Successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };
  return (
    <div className="userInfoForm">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialState}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <input
              style={
                touched.name && errors.name ? { border: '1px solid red' } : null
              }
              value={values.name}
              className="userInfoForm-name"
              onChange={handleChange}
              type="text"
              placeholder="Name"
              name="name"
            />
            <p
              style={
                touched.name && errors.name
                  ? { textAlign: 'left', marginTop: '10px', color: 'red' }
                  : { display: 'none' }
              }
              className="userInfo-name-err"
            >
              {touched.name && errors.name}
            </p>
            <input
              style={
                touched.email && errors.email
                  ? { border: '1px solid red' }
                  : null
              }
              value={values.email}
              className="userInfoForm-email"
              onChange={handleChange}
              type="email"
              placeholder="Email"
              name="email"
            />
            <p
              style={
                touched.email && errors.email
                  ? { textAlign: 'left', marginTop: '10px', color: 'red' }
                  : { display: 'none' }
              }
              className="userInfo-email-err"
            >
              {touched.email && errors.email}
            </p>
            <input
              style={
                touched.message && errors.message
                  ? { border: '1px solid red' }
                  : null
              }
              value={values.message}
              onChange={handleChange}
              className="userInfoForm-telephon"
              type="text"
              placeholder="Mensaje"
              name="message"
            />
            <p
              style={
                touched.message && errors.message
                  ? { textAlign: 'left', marginTop: '10px', color: 'red' }
                  : { display: 'none' }
              }
              className="userInfo-message-err"
            >
              {touched.message && errors.message}
            </p>
            <button type="submit" className="enviar-btn">
              Enviar
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UserInfoForm;

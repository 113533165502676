/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { getDatabase, ref, set, get } from 'firebase/database';
import { ref as sRef } from 'firebase/storage';
import { app, storage } from '../../../../firebase';
import './kycForm.css';
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-datepicker/dist/react-datepicker.css';
import GeneralInformation from './components/generalInformation';
import CompanyInformation from './components/companyInformation';
import LegalRepresentationInformation from './components/legalRepresentationInformation';
import {
  browserName,
  osName,
  deviceType,
  osVersion,
  mobileVendor,
  mobileModel,
} from 'react-device-detect';
import CompanyGeneralInformation from './components/companyGeneralInformation';

const db = getDatabase(app);

const KybForm = ({ activeStep, setActiveStep }) => {
  const [articlesOfIncorporationProgress, setArticlesOfIncorporationProgress] =
    useState(0);
  const [businessLicenseProgress, setBusinessLicenseProgress] = useState(0);
  const [societyPatentProgress, setSocietyPatentProgress] = useState(0);
  const [rTUProgress, setRTUProgress] = useState(0);
  const [
    legalRepresentativesIDCardFrontProgress,
    setLegalRepresentativesIDCardFrontProgress,
    legalRepresentativesIDCardHoldProgress
  ] = useState(0);
  const [
    legalRepresentativesIDCardBackProgress,
    setLegalRepresentativesIDCardBackProgress,
    setLegalRepresentativesIDCardHoldProgress,
  ] = useState(0);
  const [
    appointmentOfLegalRepresentativeProgress,
    setAppointmentOfLegalRepresentativeProgress,
  ] = useState(0);
  const INITIAL_VALUES = {
    companyPhoneNumber: '',
    companyName: '',
    email: '',
    businessReason: '',
    businessActivity: '',
    businessFundsSource: '',
    articlesOfIncorporation: '',
    extraDoc: '',
    businessLicense: '',
    societyPatent: '',
    RTU: '',
    companyNit: '',
    legalRepresentativesIDCardFront: '',
    legalRepresentativesIDCardBack: '',
    legalRepresentativesIDCardHold: '',
    appointmentOfLegalRepresentative: '',
    ip: '',
    ipInfo: null,
  };
  const [kybData, setKybData] = useState(INITIAL_VALUES);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      const ip = data.ip;
      setKybData({ ...kybData, ip: ip, ipInfo: data });
    };

    fetchData();
  }, []);

  const resetFields = () => {
    setArticlesOfIncorporationProgress(0);
    setBusinessLicenseProgress(0);
    setSocietyPatentProgress(0);
    setRTUProgress(0);
    setLegalRepresentativesIDCardFrontProgress(0);
    setLegalRepresentativesIDCardBackProgress(0);
    setLegalRepresentativesIDCardHoldProgress(0);
    setAppointmentOfLegalRepresentativeProgress(0);
    setKybData({
      companyPhoneNumber: '',
      companyName: '',
      email: '',
      businessReason: '',
      businessActivity: '',
      businessFundsSource: '',
      articlesOfIncorporation: '',
      extraDoc: '',
      businessLicense: '',
      societyPatent: '',
      RTU: '',
      companyNit: '',
      legalRepresentativesIDCardFront: '',
      legalRepresentativesIDCardHold: '',
      legalRepresentativesIDCardBack: '',
      appointmentOfLegalRepresentative: '',
    });
  };
  const sendEmail = () => {
    const emailContent = {
      user_name: kybData.companyName,
      user_email: kybData.email,
      // user_name: 'Khurram',
      // user_email: 'khurramkiiman@gmail.com',
      subject: 'Thank you for your response',
      message: `Thank you ${kybData.name}. submission received succesfully`,
      // message: `Thank you Khurram. submission received succesfully`,
    };
    emailjs
      .send(
        'service_wco4flm',
        'template_eikedqy',
        emailContent,
        'Gw3IGZc93ZZdsq06Z'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const sendEmailAdmin = (formPath, ipInfo = null) => {
    const emailContent = {
      form_type: 'KYB',
      form_path: formPath,
      from_name: kybData.companyName,
      company_name: kybData.companyName,
      company_phone: kybData.companyPhoneNumber,
      company_email: kybData.email,
      company_rtu: kybData.RTU,
      company_nit: kybData.companyNit,
      subject: 'Thank you for your response',
      frontDPI: kybData.legalRepresentativesIDCardFront,
      holdDPI: kybData.legalRepresentativesIDCardHold,
      backDPI: kybData.legalRepresentativesIDCardBack,
      legal_appointment: kybData.appointmentOfLegalRepresentative,
      company_articles: kybData.articlesOfIncorporation,
      company_license: kybData.businessLicense,
      company_society: kybData.societyPatent,
      ip_info: JSON.stringify(ipInfo),
      message: ``,
    };
    emailjs
      .send(
        'service_wco4flm',
        'template_04mx8l3',
        emailContent,
        'Gw3IGZc93ZZdsq06Z'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const replaceSpecialCharacters = (email) => {
    // Define the regular expression pattern for the special characters
    const pattern = /[.#$[\]]/g;

    // Replace the special characters with an empty string
    const sanitizedEmail = email.replace(pattern, '');

    return sanitizedEmail;
  };

  const storeDataInDB = async () => {
    // saving directly into email path, to avoid duplicate records for the same user
    const folderPath = `${replaceSpecialCharacters(kybData.email)}/1`;
    let formPath = 'kybs/' + folderPath;

    // IP INFO
    const ipResponse = await fetch('https://ipapi.co/json/');
    const ipData = await ipResponse.json();
    const ip = ipData.ip;

    // Check if a record already exists at the specified path
    get(ref(db, formPath))
      .then((snapshot) => {
        if (snapshot.exists()) {
          // Record already exists, throw an error
          toast('❌ Error: Ya existe un registro bajo esta cuenta de correo.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          // throw new Error('A record already exists under this location.');
          console.log('record exists...');
        } else {
          // Record does not exist, save the data
          // Add the browser information to the kycData object
          const kybDataWithBrowser = {
            ...kybData,
            insertedAt: new Date().toLocaleString('en-US', {
              timeZone: 'America/Guatemala',
              hour12: false,
            }),
            ip: ip,
            ipInfo: ipData,
            deviceInfo: {
              deviceType: deviceType,
              browserName: browserName,
              osName: osName,
              osVersion: osVersion,
              mobileVendor: mobileVendor,
              mobileModel: mobileModel,
            },
          };
          set(ref(db, formPath), kybDataWithBrowser);
          sendEmail();
          sendEmailAdmin(formPath, ipData);
          toast('✅ Formulario enviado éxitosamente!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
    resetFields();
    setTimeout(() => {
      setActiveStep(0);
    }, 400);
  };
  const handleUploadClick = async (e, name, setFieldValue) => {
    if (e.target.files.length < 1) return;
    const file = e.target.files[0];
    // change file name
    const myNewFile = new File([file], new Date().getTime() + file.name, {
      type: file.type,
    });
    const sotrageRef = sRef(storage, `kybs/${myNewFile.name}`);
    const uploadTask = uploadBytesResumable(sotrageRef, myNewFile);
    uploadTask?.on(
      'state_changed',
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        if (name === 'articlesOfIncorporation') {
          setArticlesOfIncorporationProgress(prog);
        } else if (name === 'businessLicense') {
          setBusinessLicenseProgress(prog);
        } else if (name === 'societyPatent') {
          setSocietyPatentProgress(prog);
        } else if (name === 'RTU') {
          setRTUProgress(prog);
        } else if (name === 'legalRepresentativesIDCardFront') {
          setLegalRepresentativesIDCardFrontProgress(prog);
        } else if (name === 'legalRepresentativesIDCardBack') {
          setLegalRepresentativesIDCardBackProgress(prog);
        } else if (name === 'legalRepresentativesIDCardHold') {
          setLegalRepresentativesIDCardHoldProgress(prog);
        } else if (name === 'appointmentOfLegalRepresentative') {
          setAppointmentOfLegalRepresentativeProgress(prog);
        }
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setFieldValue(name, downloadURL);
          setKybData({ ...kybData, [name]: downloadURL });
        });
      }
    );
  };
  const handleNext = (values, final = false) => {
    setKybData({ ...kybData, ...values });
    if (final) {
      storeDataInDB();
      return;
    }
    setActiveStep(activeStep + 1);
  };
  const handlePrevious = (values) => {
    setKybData({ ...kybData, ...values });
    setActiveStep(activeStep - 1);
  };
  return (
    <div className="kycForm">
      <div className="inner">
        {activeStep === 0 && (
          <GeneralInformation
            kybData={kybData}
            setKybData={setKybData}
            activeStep={activeStep}
            handleNext={handleNext}
          />
        )}
        {activeStep === 1 && (
          <CompanyInformation
            kybData={kybData}
            setKybData={setKybData}
            handleUploadClick={handleUploadClick}
            activeStep={activeStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            articlesOfIncorporationProgress={articlesOfIncorporationProgress}
            businessLicenseProgress={businessLicenseProgress}
            societyPatentProgress={societyPatentProgress}
            rTUProgress={rTUProgress}
          />
        )}
        {activeStep === 2 && (
            <CompanyGeneralInformation
            kybData={kybData}
            setKybData={setKybData}
            handleUploadClick={handleUploadClick}
            activeStep={activeStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            articlesOfIncorporationProgress={articlesOfIncorporationProgress}
            businessLicenseProgress={businessLicenseProgress}
            societyPatentProgress={societyPatentProgress}
            rTUProgress={rTUProgress}
          />
        )}
        {activeStep === 3 && (
          <LegalRepresentationInformation
            kybData={kybData}
            setKybData={setKybData}
            handleUploadClick={handleUploadClick}
            legalRepresentativesIDCardFrontProgress={
              legalRepresentativesIDCardFrontProgress
            }
            legalRepresentativesIDCardBackProgress={
              legalRepresentativesIDCardBackProgress
            }
            legalRepresentativesIDCardHoldProgress={
              legalRepresentativesIDCardHoldProgress
            }
            appointmentOfLegalRepresentativeProgress={
              appointmentOfLegalRepresentativeProgress
            }
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        )}
      </div>
    </div>
  );
};

export default KybForm;

/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import { v4 as uuidv4 } from 'uuid';
import { getDatabase, ref, set } from 'firebase/database';
import { ref as sRef } from 'firebase/storage';
import { app, storage } from '../../../../firebase';
import SimpleCustomSelect from '../../../../components/simpleCustomSelect';
import KycInput from './components/kycInput';
import UploadImage from './components/uploadFile';
import DatePicker from 'react-date-picker';
import './kycForm.css';
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import CountryDropdown from './components/countryDropdown';
import {
  browserName,
  osName,
  deviceType,
  osVersion,
  mobileVendor,
  mobileModel,
} from 'react-device-detect';
import 'react-datepicker/dist/react-datepicker.css';
const db = getDatabase(app);

const KycForm = () => {
  const [progressFrontDPI, setProgressFrontDPI] = useState(0);
  const [progressBackDPI, setProgressBackDPI] = useState(0);
  const [progressHoldingDPI, setProgressHoldingDPI] = useState(0);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [kycData, setKycData] = useState({
    name: '',
    surName: '',
    dpi: '',
    email: '',
    phone: '',
    gender: '',
    birthDate: new Date(),
    country: null,
    address: '',
    profesionOficio: '',
    tin: '',
    sourseOfFunds: '',
    estimatedRange: '',
    frontDPI: '',
    backDPI: '',
    holdingDPI: '',
    ip: '',
    ipInfo: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      const ip = data.ip;
      setKycData({ ...kycData, ip: ip, ipInfo: data });
    };

    fetchData();
  }, []);

  const handleField = (e) => {
    if (e.target.name && e.target.name == 'email') {
      // if email, remove spaces and not allowed characters.
      let tmpVal = e.target.value.replace(/[^\w@.!#$%&'*+\-\/=?^_`{|}~]/g, '');
      e.target.value = tmpVal.toLowerCase();
    }
    setKycData({ ...kycData, [e.target.name]: e.target.value });
  };
  const handleCountry = (value) => {
    setKycData({ ...kycData, country: value });
  };
  const handleGender = (value) => {
    setKycData({ ...kycData, gender: value });
  };
  const handleEstimatedRange = (value) => {
    setKycData({ ...kycData, estimatedRange: value });
  };
  const handleBirthDate = (value) => {
    setKycData({ ...kycData, birthDate: value });
  };

  const resetFields = () => {
    setProgressFrontDPI(0);
    setProgressBackDPI(0);
    setProgressHoldingDPI(0);
    setKycData({
      name: '',
      surName: '',
      dpi: '',
      email: '',
      phone: '',
      gender: '',
      birthDate: new Date(),
      country: null,
      address: '',
      profesionOficio: '',
      tin: '',
      sourseOfFunds: '',
      estimatedRange: '',
      frontDPI: '',
      backDPI: '',
      holdingDPI: '',
    });
  };

  const sendEmail = () => {
    const emailContent = {
      user_name: kycData.name + ' ' + kycData.surName,
      user_email: kycData.email,
      subject: 'Thank you for your response',
      message: ``,
    };
    emailjs
      .send(
        'service_wco4flm',
        'template_eikedqy',
        emailContent,
        'Gw3IGZc93ZZdsq06Z'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const sendEmailAdmin = (formPath) => {
    const emailContent = {
      form_type: 'KYC',
      from_name: `${kycData.name} ${kycData.surName}`,
      user_name: kycData.name,
      last_name: kycData.surName,
      dpi: kycData.dpi,
      user_email: kycData.email,
      user_profesion: kycData.profesionOficio,
      user_procedencia: kycData.sourseOfFunds,
      user_range: kycData.estimatedRange,
      form_path: formPath,
      subject: 'Thank you for your response',
      frontDPI: kycData.frontDPI,
      backDPI: kycData.backDPI,
      holdingDPI: kycData.holdingDPI,
      ip_info: JSON.stringify(kycData.ipInfo),
      phone_num: kycData.phone,
      nit: kycData.tin,
      message: ``,
    };
    emailjs
      .send(
        'service_wco4flm',
        'template_04mx8l3',
        emailContent,
        'Gw3IGZc93ZZdsq06Z'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const replaceSpecialCharacters = (email) => {
    // Define the regular expression pattern for the special characters
    const pattern = /[.#$[\]]/g;

    // Replace the special characters with an empty string
    const sanitizedEmail = email.replace(pattern, '');

    return sanitizedEmail;
  };

  const handleSubmit = async () => {
    if (
      kycData.name !== '' &&
      kycData.surName !== '' &&
      kycData.dpi !== '' &&
      kycData.email !== '' &&
      // kycData.phone !== '' && // allow phone to be empty
      kycData.gender !== '' &&
      kycData.birthDate !== '' &&
      kycData.country !== null &&
      kycData.address !== '' &&
      kycData.profesionOficio !== '' &&
      kycData.tin !== '' &&
      kycData.sourseOfFunds !== '' &&
      kycData.estimatedRange !== '' &&
      kycData.frontDPI !== '' &&
      kycData.backDPI !== '' &&
      kycData.holdingDPI !== ''
    ) {
      // Get current date and time
      const now = new Date().toLocaleString('en-US', {
        timeZone: 'America/Guatemala',
        hour12: false,
      });
      const [date, time] = now.split(', ');
      const [month, day, year] = date.split('/');
      const [hour, minute, second] = time.split(':');
      // const folderPath = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}/${hour.padStart(2, "0")}h/${minute.padStart(2, "0")}m${second.padStart(2, "0")}s`;
      const folderPath = `${replaceSpecialCharacters(kycData.email)}`;

      // Add the browser information to the kycData object
      const kycDataWithBrowser = {
        ...kycData,
        insertedAt: new Date().toLocaleString('en-US', {
          timeZone: 'America/Guatemala',
          hour12: false,
        }),
        deviceInfo: {
          deviceType: deviceType,
          browserName: browserName,
          osName: osName,
          osVersion: osVersion,
          mobileVendor: mobileVendor,
          mobileModel: mobileModel,
        },
      };
      let formPath = 'kycs/' + folderPath + '/' + uuidv4({ format: 'short' });
      set(ref(db, formPath), kycDataWithBrowser);
      sendEmail();
      sendEmailAdmin(formPath);
      resetFields();
      alert('Formulario enviado exitosamente.');
    } else {
      console.log(kycData);
      alert('Por favor, rellene todos los campos obligatorios.');
    }
  };

  //handle the image uploading process
  const handleUploadClick = async (e, name) => {
    if (e.target.files.length < 1) return;
    const file = e.target.files[0];
    // change file name
    const myNewFile = new File([file], new Date().getTime() + file.name, {
      type: file.type,
    });
    const sotrageRef = sRef(storage, `kycs/${myNewFile.name}`);
    const uploadTask = uploadBytesResumable(sotrageRef, myNewFile);
    uploadTask?.on(
      'state_changed',
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        if (name === 'frontDPI') {
          setProgressFrontDPI(prog);
        } else if (name === 'backDPI') {
          setProgressBackDPI(prog);
        } else if (name === 'holdingDPI') {
          setProgressHoldingDPI(prog);
        }
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setKycData({ ...kycData, [name]: downloadURL });
        });
      }
    );
  };
  //handle the image uploading process

  return (
    <div className="kycForm">
      <div className="inner">
        <KycInput
          type="text"
          name="name"
          value={kycData.name}
          handleField={handleField}
          placeholder="Nombres*"
        />
        <KycInput
          type="text"
          name="surName"
          value={kycData.surName}
          handleField={handleField}
          placeholder="Apellidos*"
        />
        <KycInput
          type="text"
          name="dpi"
          value={kycData.dpi}
          handleField={handleField}
          placeholder="DPI *"
        />
        <KycInput
          type="text"
          name="email"
          value={kycData.email}
          handleField={handleField}
          placeholder="Email*"
        />
        <KycInput
          type="text"
          name="phone"
          value={kycData.phone}
          handleField={handleField}
          placeholder="Número de teléfono (opcional)"
        />
        <SimpleCustomSelect
          type="text"
          name="gender"
          value={kycData.gender}
          handleField={handleGender}
          setFieldValue={(name, v) => {}}
          placeholder="Género*"
          options={['Masculino', 'Femenino']}
        />
        {/* <KycInput
          type="text"
          name="birthDate"
          value={kycData.birthDate}
          handleField={handleField}
          placeholder="Fecha de nacimiento*"
        /> */}
        <div className="birthDate1">
          <p className="birthDateLabel">Fecha de nacimiento*</p>
          <DatePicker
            placeholderText="Fecha de nacimiento*"
            value={kycData.birthDate}
            onChange={(date) => handleBirthDate(date)}
          />
        </div>
        <CountryDropdown
          type="text"
          name="country"
          value={kycData.country}
          handleField={handleCountry}
          placeholder="País de origen*"
        />
        <KycInput
          type="text"
          name="address"
          value={kycData.address}
          handleField={handleField}
          placeholder="Direccion o departamento*"
        />
        <KycInput
          type="text"
          name="tin"
          value={kycData.tin}
          handleField={handleField}
          placeholder="NIT o CF*"
        />
        <KycInput
          type="text"
          name="profesionOficio"
          value={kycData.profesionOficio}
          handleField={handleField}
          placeholder="Profesión u oficio*"
        />
        <KycInput
          type="text"
          name="sourseOfFunds"
          value={kycData.sourseOfFunds}
          handleField={handleField}
          placeholder="Origen o procedencia de los fondos*"
        />
        <SimpleCustomSelect
          type="text"
          name="estimatedRange"
          value={kycData.estimatedRange}
          handleField={handleEstimatedRange}
          setFieldValue={(name, v) => {}}
          placeholder="Rango Estimado Mensual*"
          options={['$10-$500', '$500-$1,000', '$1,000-$3,000', '$3,000-$10,000', '$10,000+']}
        />
        <p className="kycForm-txt1">
          Parámetros para la foto de tus documentos
        </p>
        <p className="kycForm-txt2">
          Foto o documento en PDF de DPI / Pasaporte Frontal
        </p>
        <UploadImage
          name="frontDPI"
          handleUploadClick={handleUploadClick}
          text="Cargar Foto"
        />
        &nbsp; &nbsp; &nbsp;
        {progressFrontDPI > 0 ? (
          <span>
            Cargado &nbsp;
            <span style={{ color: 'green' }}>{progressFrontDPI} %</span>
          </span>
        ) : null}
        &nbsp; &nbsp; &nbsp;
        {kycData.frontDPI && (
          <a href={kycData.frontDPI} target="_blank">
            Visualizar
          </a>
        )}
        <p className="kycForm-txt2">
          Foto o documento en PDF de DPI / Pasaporte Trasera
        </p>
        <UploadImage
          name="backDPI"
          handleUploadClick={handleUploadClick}
          text="Cargar Foto"
        />
        &nbsp; &nbsp; &nbsp;
        {progressBackDPI > 0 ? (
          <span>
            Cargado &nbsp;
            <span style={{ color: 'green' }}>{progressBackDPI} %</span>
          </span>
        ) : null}
        &nbsp; &nbsp; &nbsp;
        {kycData.backDPI && (
          <a href={kycData.backDPI} target="_blank">
            Visualizar
          </a>
        )}
        <p className="kycForm-txt2">Foto sosteniendo tu DPI / Pasaporte</p>
        <UploadImage
          name="holdingDPI"
          handleUploadClick={handleUploadClick}
          text="Cargar Foto"
        />
        &nbsp; &nbsp; &nbsp;
        {progressHoldingDPI > 0 ? (
          <span>
            Cargado &nbsp;
            <span style={{ color: 'green' }}>{progressHoldingDPI} %</span>
          </span>
        ) : null}
        &nbsp; &nbsp; &nbsp;
        {kycData.holdingDPI && (
          <a href={kycData.holdingDPI} target="_blank">
            Visualizar
          </a>
        )}
        <div className="kycForm-terms">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
          />
          <label htmlFor="terms">
            He leído y acepto los{' '}
            <a href="/términos-y-políticas" target="_blank">
              {' '}
              términos y condiciones
            </a>
            .
          </label>
        </div>
        <div className="kycForm-enter-btn">
          <button
            className="kycForm-submit-button"
            onClick={() => handleSubmit()}
            disabled={!termsAccepted}
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

export default KycForm;

/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { getDatabase, ref, set, get } from 'firebase/database';
import { ref as sRef } from 'firebase/storage';
import { app, storage } from '../../../../firebase';
import './kycForm.css';
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import GeneralDetails from './components/generalDetails';
import PersonalDetails from './components/personalDetails';
import ProfesionalDetails from './components/profesionalDetails';
import IncomeDetails from './components/incomeDetails';
import DocumentUploads from './components/documentUploads';
import {
  browserName,
  osName,
  deviceType,
  osVersion,
  mobileVendor,
  mobileModel,
} from 'react-device-detect';

const db = getDatabase(app);

const KycForm = ({ activeStep, setActiveStep }) => {
  const [progressFrontDPI, setProgressFrontDPI] = useState(0);
  const [progressBackDPI, setProgressBackDPI] = useState(0);
  const [progressHoldingDPI, setProgressHoldingDPI] = useState(0);
  const INITIAL_VALUES = {
    name: '',
    surName: '',
    email: '',
    phone: '',
    gender: '',
    birthDate: new Date(),
    country: null,
    address: '',
    profesionOficio: '',
    dpi: '',
    tin: '',
    sourseOfFunds: '',
    estimatedRange: '',
    frontDPI: '',
    backDPI: '',
    holdingDPI: '',
    ip: '',
    ipInfo: null,
  };
  const [kycData, setKycData] = useState(INITIAL_VALUES);

  const resetFields = () => {
    setProgressFrontDPI(0);
    setProgressBackDPI(0);
    setProgressHoldingDPI(0);
    setKycData({
      name: '',
      surName: '',
      email: '',
      phone: '',
      gender: '',
      birthDate: new Date(),
      country: null,
      address: '',
      profesionOficio: '',
      dpi: '',
      tin: '',
      sourseOfFunds: '',
      estimatedRange: '',
      frontDPI: '',
      backDPI: '',
      holdingDPI: '',
    });
  };

  const sendEmail = () => {
    const emailContent = {
      user_name: kycData.name + ' ' + kycData.surName,
      user_email: kycData.email,
      subject: 'Thank you for your response',
      message: `Thank you ${kycData.name}. submission received succesfully`,
    };
    emailjs
      .send(
        'service_wco4flm',
        'template_eikedqy',
        emailContent,
        'Gw3IGZc93ZZdsq06Z'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const sendEmailAdmin = (formPath, ipInfo = null) => {

    const emailContent = {
      form_type: "KYC",
      form_path: formPath,
      from_name: `${kycData.name} ${kycData.surName}`,
      user_name: kycData.name,
      last_name: kycData.surName,
      dpi: kycData.dpi,
      user_email: kycData.email,
      user_profesion: kycData.profesionOficio,
      user_procedencia: kycData.sourseOfFunds,
      user_range: kycData.estimatedRange,
      subject: 'Thank you for your response',
      frontDPI: kycData.frontDPI,
      backDPI: kycData.backDPI,
      holdingDPI: kycData.holdingDPI,
      ip_info: JSON.stringify(ipInfo),
      phone_num: kycData.phone,
      nit: kycData.tin,
      message: ``,
    };
    emailjs
      .send(
        'service_wco4flm',
        'template_04mx8l3',
        emailContent,
        'Gw3IGZc93ZZdsq06Z'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const replaceSpecialCharacters = (email) => {
    // Define the regular expression pattern for the special characters
    const pattern = /[.#$[\]]/g;

    // Replace the special characters with an empty string
    const sanitizedEmail = email.replace(pattern, '');

    return sanitizedEmail;
  };

  const storeDataInDB = async () => {
    // saving directly into email path, to avoid duplicate records for the same user
    const folderPath = `${replaceSpecialCharacters(kycData.email)}/1`;
    let formPath = 'kycs/' + folderPath;

    // IP INFO
    const ipResponse = await fetch('https://ipapi.co/json/');
    const ipData = await ipResponse.json();
    const ip = ipData.ip;

    // Check if a record already exists at the specified path
    get(ref(db, formPath)).then(async (snapshot) => {

      if (snapshot.exists()) {
        // Record already exists, throw an error
        toast('❌ Error: Ya existe un registro bajo esta cuenta de correo.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        // throw new Error('A record already exists under this location.');
        console.log('record exists...');
      } else {
        // Record does not exist, save the data
        // Add the browser information to the kycData object
        const kycDataWithBrowser = {
          ...kycData,
          insertedAt: new Date().toLocaleString('en-US', {
            timeZone: 'America/Guatemala',
            hour12: false,
          }),
          ip: ip,
          ipInfo: ipData,
          deviceInfo: {
            deviceType: deviceType,
            browserName: browserName,
            osName: osName,
            osVersion: osVersion,
            mobileVendor: mobileVendor,
            mobileModel: mobileModel,
          },
        };
        set(ref(db, formPath), kycDataWithBrowser);
        sendEmail();
        sendEmailAdmin(formPath, ipData);
        toast('✅ Formulario enviado éxitosamente!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }).catch((error) => {
      // Handle error
      console.error(error);
    });
    resetFields();
    setTimeout(() => {
      setActiveStep(0);
    }, 500);
  };
  const handleUploadClick = async (e, name, setFieldValue) => {
    if (e.target.files.length < 1) return;
    const file = e.target.files[0];
    // change file name
    const myNewFile = new File([file], new Date().getTime() + file.name, {
      type: file.type,
    });
    const sotrageRef = sRef(storage, `kycs/${myNewFile.name}`);
    const uploadTask = uploadBytesResumable(sotrageRef, myNewFile);
    uploadTask?.on(
      'state_changed',
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        if (name === 'frontDPI') {
          setProgressFrontDPI(prog);
        } else if (name === 'backDPI') {
          setProgressBackDPI(prog);
        } else if (name === 'holdingDPI') {
          setProgressHoldingDPI(prog);
        }
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setFieldValue(name, downloadURL);
          setKycData({ ...kycData, [name]: downloadURL });
        });
      }
    );
  };
  const handleNext = (values, final = false) => {
    setKycData({ ...kycData, ...values });
    if (final) {
      storeDataInDB();
      return;
    }
    setActiveStep(activeStep + 1);
  };
  const handlePrevious = (values) => {
    setKycData({ ...kycData, ...values });
    setActiveStep(activeStep - 1);
  };
  return (
    <div className="kycForm">
      <div className="inner">
        {activeStep === 0 && (
          <GeneralDetails
            kycData={kycData}
            setKycData={setKycData}
            activeStep={activeStep}
            handleNext={handleNext}
          />
        )}
        {activeStep === 1 && (
          <PersonalDetails
            kycData={kycData}
            setActiveStep={setActiveStep}
            setKycData={setKycData}
            activeStep={activeStep}
            handleNext={handleNext}
          />
        )}
        {activeStep === 2 && (
          <ProfesionalDetails
            kycData={kycData}
            setKycData={setKycData}
            activeStep={activeStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        )}
        {activeStep === 3 && (
          <IncomeDetails
            kycData={kycData}
            setKycData={setKycData}
            activeStep={activeStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        )}
        {activeStep === 4 && (
          <DocumentUploads
            kycData={kycData}
            handleUploadClick={handleUploadClick}
            progressFrontDPI={progressFrontDPI}
            progressBackDPI={progressBackDPI}
            progressHoldingDPI={progressHoldingDPI}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        )}
      </div>
    </div>
  );
};

export default KycForm;

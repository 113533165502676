/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import CreatePost from './components/createPost';
import PostComment from './components/postComment';
import { getDatabase, ref, set, onValue } from 'firebase/database';
import { app } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';
import './posts.css';
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(8),
  },
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    marginTop: "15px",
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    },
  },
  cardImage: {
    height: "250px",
    objectFit: "cover",
    borderRadius: "8px 8px 0 0",
  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  cardTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  cardSubtitle: {
    color: "gray",
    marginBottom: theme.spacing(2),
  },
  cardCategory: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  cardBody: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    overflowWrap: "break-word",
    maxHeight: "300px", // Add this line to limit the height
    overflow: "hidden", // Add this line to hide the overflow
  },
}));

const db = getDatabase(app);

const Posts = () => {
  const classes = useStyles();
  const [posts, setPosts] = useState([]);

  const comunidadRef = ref(db, 'comunidad/');

  const getAllComments = () => {
    onValue(comunidadRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const postArray = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        setPosts(postArray);
      }
    });
  };

  const handlePostClick = (postId) => {
    window.open(`/comunidad/post/${postId}`, "_blank");
  };

  useEffect(() => {
    getAllComments();
  }, []);

  return (
    <div className={classes.card}>
    <Container maxWidth="md">
      <Grid container spacing={3} className={classes.gridContainer}>
        {posts.map((post) => (
          <Grid item xs={12} key={post.id}>
            <div className={classes.card} onClick={() => handlePostClick(post.id)}>
              <img
                src={post.bannerImage}
                alt={post.title}
                className={classes.cardImage}
              />
              <div className={classes.cardContent}>
                <Typography variant="subtitle2" className={classes.cardCategory}>
                  {post.category}
                </Typography>
                <Typography variant="h5" component="h2" className={classes.cardTitle}>
                  {post.title}
                </Typography>
                <Typography variant="subtitle1" className={classes.cardSubtitle}>
                  {post.subtitle}
                </Typography>
                <Typography 
                  variant="body1" className={classes.cardBody} dangerouslySetInnerHTML={{__html: post.body}}
                >
                </Typography>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  </div>
  );
};

export default Posts;

import React from 'react';
import './kycInput.css';

const KycInput = ({ error, type, name, value, placeholder, handleField }) => {
  return (
    <div className="kycInput">
      <input
        style={error ? { border: '1px solid red' } : null}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleField}
      />
      <p
        style={
          error
            ? { textAlign: 'left', marginTop: '10px', color: 'red' }
            : { display: 'none' }
        }
      >
        {error}
      </p>
    </div>
  );
};

export default KycInput;

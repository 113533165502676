import React from 'react';
import { Link } from 'react-router-dom';
import socialIcon1 from '../../assets/images/socialIcon1.svg';
import socialIcon2 from '../../assets/images/socialIcon2.svg';
import socialIcon3 from '../../assets/images/socialIcon3.svg';
import socialIcon4 from '../../assets/images/socialIcon4.svg';
import socialIcon5 from '../../assets/images/socialIcon5.svg';
import logoWhite from '../../assets/images/logo-white.png';
import './footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-inner">
        <div>
          <h1 className="footer-logo">
          <img
              className="footer-logo-img"
              src={logoWhite}
              alt="logoWhite"
            />
          </h1>
          <p className="footer-text1">
            Seguridad y confianza en todas tus transacciones
          </p>
          <br/>
          {/* <p className="footer-text2">
          </p> */}
          <div className="footer-socialIcons">
            <img src={socialIcon3} alt="socialIcon3" />
            <div className="footer-socialIcon">
              <img src={socialIcon4} alt="socialIcon4" />
            </div>
            <div className="footer-socialIcon">
              <img src={socialIcon5} alt="socialIcon5" />
            </div>
          </div>
        </div>
        <div className="spacer154" />
        <div>
          <h2 className="footer-nav-h">Navegación</h2>
          <ul className="footer-links">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/kyc">Identificación</Link>
            </li>
            <li>
              <Link to="/kyb">Identificación de Negocios</Link>
            </li>
            <li>
              <Link to="/comunidad">Comprar y Vender</Link>
            </li>
            <li>
              <Link to="/about">Acerca De</Link>
            </li>
          </ul>
        </div>
        <div className="spacer113" />
        <div>
          <h2 className="footer-contacts-h">Contáctanos</h2>
          <div className="footer-contact-info">
          <a className="footer-linkText" href={"mailto:" + "contacto@ortech.com.gt"}><p>contacto@ortech.com.gt</p></a>
          <a className="footer-linkText" href={"tel:" + "+50230994927"}><p>+502 3099 4927</p></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@mui/material';
import KycInput from '../kycInput';
import SimpleCustomSelect from '../../../../../../components/simpleCustomSelect';

const IncomeDetails = ({
  kycData,
  setKycData,
  activeStep,
  handleNext,
  handlePrevious,
}) => {
  const checkoutSchema = yup.object().shape({
    profesionOficio: yup.string().required('Requerido'),
    sourseOfFunds: yup.string().required('Requerido'),
    estimatedRange: yup.string().required('Requerido'),
  });
  const handleEstimatedRange = (value) => {
    setKycData({ ...kycData, estimatedRange: value });
  };
  const handleFormSubmit = async (values) => {
    handleNext(values);
  };
  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={kycData}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <>
            <KycInput
              error={touched.profesionOficio && errors.profesionOficio}
              type="text"
              name="profesionOficio"
              value={values.profesionOficio}
              handleField={handleChange}
              placeholder="Profesión u oficio*"
            />
            <KycInput
              error={touched.sourseOfFunds && errors.sourseOfFunds}
              type="text"
              name="sourseOfFunds"
              value={values.sourseOfFunds}
              handleField={handleChange}
              placeholder="Origen o procedencia de los fondos*"
            />
            <SimpleCustomSelect
              error={touched.estimatedRange && errors.estimatedRange}
              type="text"
              name="estimatedRange"
              value={values.estimatedRange}
              handleField={handleEstimatedRange}
              setFieldValue={setFieldValue}
              placeholder="Rango Estimado Mensual*"
              options={['$10-$500', '$500-$1,000', '$1,000-$3,000', '$3,000-$10,000', '$10,000+']}
            />
          </>
          <div className="stepperkycForm-enter-btn">
            <Button
              variant="contained"
              onClick={() => handlePrevious(values)}
              disabled={activeStep === 0}
            >
              Anterior
            </Button>
            <Button type="submit" variant="contained">
              Siguiente
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default IncomeDetails;

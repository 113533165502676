import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, Paper } from "@material-ui/core";
import { app } from '../../../firebase';

const useStyles = makeStyles((theme) => ({
    container: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(8),
    },
    paper: {
      padding: theme.spacing(4),
      borderRadius: "8px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    },
    title: {
      fontWeight: "bold",
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      marginBottom: theme.spacing(1),
    },
    category: {
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(2),
    },
    image: {
      width: "100%",
      maxHeight: "400px",
      objectFit: "cover",
      borderRadius: "8px",
      marginBottom: theme.spacing(2),
    },
    body: {
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
      overflowWrap: "break-word",
    },
  }));

const db = getDatabase(app);

const PostPage = () => {
  const classes = useStyles();
  const { postId } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const postRef = ref(db, `comunidad/${postId}`);

    const fetchPostData = () => {
      onValue(postRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
            console.log('data:');
            console.log(data);
            setPost({ id: postId, ...data });
        }
      });
    };

    fetchPostData();
  }, [postId]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            {post.title}
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {post.subtitle}
          </Typography>
          <Typography variant="subtitle2" className={classes.category}>
            {post.category}
          </Typography>
          <img src={post.bannerImage} alt={post.title} className={classes.image} />
          <Typography
            variant="body1"
            className={classes.body}
            dangerouslySetInnerHTML={{ __html: post.body }}
          ></Typography>
        </Paper>
      </Grid>
    </Grid>
  </Container>
  );
};

export default PostPage;

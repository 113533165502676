/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { countries } from 'countries-list';
import './countryDropdown.css';
import { Divider } from '@material-ui/core';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, setOpen) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert('You clicked outside of me!');
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */

const CountryDropdown = ({
  error,
  type,
  name,
  value,
  placeholder,
  handleField,
  setFieldValue,
}) => {
  const [myCountries, setMyCountries] = useState(Object.values(countries));
  const wrapperRef = useRef(null);
  const [searchText, setSearchText] = useState();
  const [open, setOpen] = useState(false);
  useOutsideAlerter(wrapperRef, setOpen);
  const toggle = () => {
    setOpen((prev) => !prev);
  };
  const handleCountry = (v) => {
    handleField(v);
    setFieldValue(name, v);
    setMyCountries(Object.values(countries));
    setSearchText('');
    setOpen(false);
  };
  const handleSearch = (txt) => {
    setSearchText(txt);
    if (txt === '') {
      setMyCountries(Object.values(countries));
    } else {
      setMyCountries(
        Object.values(countries).filter((country) =>
          country.name.toLowerCase().includes(txt.toLowerCase())
        )
      );
    }
  };
  return (
    <>
      <div
        ref={wrapperRef}
        className="countryDropdown"
        style={error ? { border: '1px solid red' } : null}
      >
        <div className="countryDropdown-selected-area" onClick={() => toggle()}>
          <span>
            {value?.name ? (
              <>
                {value.emoji}
                &nbsp;&nbsp;
                {value.name}
              </>
            ) : (
              placeholder
            )}
          </span>
          {open ? (
            <i class="fas fa-caret-up"></i>
          ) : (
            <i class="fas fa-caret-down"></i>
          )}
        </div>
        <ul
          style={{ display: open ? 'block' : 'none' }}
          className="countries-list"
        >
          <div className="countries-list-search">
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <i className="fal fa-search"></i>
          </div>
          <Divider />
          {myCountries.map((country, i) => (
            <>
              <li key={i} onClick={() => handleCountry(country)}>
                {' '}
                <span>{country.emoji}</span>
                &nbsp; &nbsp;
                <span>{country.name}</span>
              </li>
              <Divider />
            </>
          ))}
        </ul>
      </div>
      <p
        style={
          error
            ? { position: 'relative', top: -20, color: 'red' }
            : { display: 'none' }
        }
      >
        {error}
      </p>
    </>
  );
};

export default CountryDropdown;

import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@mui/material';
import KycInput from '../kycInput';
import DatePicker from 'react-date-picker';
import SimpleCustomSelect from '../../../../../../components/simpleCustomSelect';

const PersonalDetails = ({
  kycData,
  setKycData,
  activeStep,
  setActiveStep,
  handleNext,
}) => {
  const checkoutSchema = yup.object().shape({
    name: yup.string().required('Requerido'),
    surName: yup.string().required('Requerido'),
    gender: yup.string().required('Requerido'),
    birthDate: yup.date().required('Requerido'),
  });
  const handleGender = (value) => {
    setKycData({ ...kycData, gender: value });
  };
  const handleBirthDate = (value) => {
    setKycData({ ...kycData, birthDate: value });
  };
  const handleFormSubmit = async (values) => {
    console.log('values', values);
    handleNext(values);
  };
  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={kycData}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <>
            <KycInput
              error={touched.name && errors.name}
              type="text"
              name="name"
              value={values.name}
              handleField={handleChange}
              placeholder="Nombres*"
            />
            <KycInput
              error={touched.surName && errors.surName}
              type="text"
              name="surName"
              value={values.surName}
              handleField={handleChange}
              placeholder="Apellidos*"
            />
            <p className="kyc-label">Fecha de nacimiento*</p>
            <DatePicker
              error={touched.birthDate && errors.birthDate}
              className={
                touched.birthDate && errors.birthDate
                  ? 'birthDate red-border'
                  : 'birthDate'
              }
              value={kycData.birthDate}
              onChange={(date) => {
                setFieldValue('birthDate', date);
                handleBirthDate(date);
              }}
            />
            <p
              style={
                touched.birthDate && errors.birthDate
                  ? null
                  : { display: 'none' }
              }
              className="birthDateErr"
            >
              {touched.birthDate && errors.birthDate}
            </p>
            <SimpleCustomSelect
              error={touched.gender && errors.gender}
              type="text"
              name="gender"
              value={kycData.gender}
              handleField={handleGender}
              setFieldValue={setFieldValue}
              placeholder="Género*"
              options={['Masculino', 'Femenino']}
            />
          </>

          <div className="stepperkycForm-enter-btn">
            <Button
              variant="contained"
              disabled={activeStep === 0}
              onClick={() => setActiveStep((prev) => prev - 1)}
            >
              Anterior
            </Button>
            <Button type="submit" variant="contained">
              Siguiente
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default PersonalDetails;
